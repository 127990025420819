
import {get,post} from "@/axios";


export function getConfig(data) {
  return get('/api/config/get',data);
}

export function saveConfig(data){//模拟接口请求权限
  return post('/api/config/save',data); 
}