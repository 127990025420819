<template>
    <div>
      <div class="mainContent" >
        <div id="card-table">
            <h2>格式为"卡头|国家", 字段值中间以逗号分割,留空则不限制</h2>            
            <a-button type="primary" @click="saveData">保存</a-button>
            <a-divider></a-divider>
            <a-textarea v-model:value="text" :rows="20" />
        </div>
        
      </div>
    </div>
  </template>
  <script>
  import {getConfig, saveConfig} from "@/axios/config.js"
  import {myMixin} from "@/static/js/mixin.js"

  export default {
    mixins: [myMixin],
    data() {
      return {
        text:'',
      }
  
    },
    created(){
      this.fetch();      
    },
    mounted() {
    },
    methods:{
        fetch (params = {}) {
          this.loading = true;
          getConfig({...params,...this.form}).then((response) => {
            this.text = response.data.data;
            this.loading = false;
            this.pagination.total = response.data.total;
          });
        },
        saveData() {
          var post_data = {"text":this.text};
          this.loading = true;
          saveConfig({...post_data,...this.form}).then((response) => {
            this.loading = false;
            this.$message.success("保存成功");
          });
        }
      }
  };
  </script>
  <style lang="scss" scoped>
  @import "@/static/css/index.scss";
  .ant-table td { white-space: nowrap; }
  .ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
  </style>
  